// REACT
import React from "react";
import {Helmet} from "react-helmet";

// COMPONENT
import Navbar from "../Component/Navbar";
import Headers from "../Component/Headers";
import VisiMisiPage from "../Component/Visi-misi"
import Footer from "../Component/Footer";

function VisiMisi() {

    return (
        <>
            <Helmet>
                <title>SMK BUDDHI || Visi dan Misi</title>
                <meta name="descripti on"
                      content="Alamat. Jl. Imam Bonjol No.41, RT.002/RW.003, Karawaci, Kec. Tangerang, Banten, 15115."/>
                <meta name="keywords"
                      content="SMK BUDDHI, SMK DI TANGERANG, SMK BUDDHI DHARMA, SMK Tangerang, smk buddhi, SMK Buddhi, visi dan misi smk buddhi, visi misi smk buddhi, Visi Dan Misi SMK Buddhi, Visi Misi SMK Buddhi"/>
                <meta name="author" content="SMK BUDDHI"/>
                <meta property="og:title" content="SMK BUDDHI"/>
                <meta property="og:description"
                      content="Alamat. Jl. Imam Bonjol No.41, RT.002/RW.003, Karawaci, Kec. Tangerang, Banten, 15115."/>
                <meta property="og:url" content="https://smkbuddhi.sch.id/visi-misi"/>
            </Helmet>

            <div className="VisiMisi">
                <Navbar/>
                <Headers title="Visi Dan Misi" desc="Visi dan Misi Sekolah Menengah Kejuruan Perguruan Buddhi."/>
                <VisiMisiPage/>
                <footer className="bg-red-500">
                    <Footer/>
                </footer>
            </div>
        </>
    );
}

export default VisiMisi;
