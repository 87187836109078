import {Config} from "../config";

// REACT
import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet";

// HELPER
import axios from "axios";

// ROUTER
import {useSearchParams} from "react-router-dom";

// ICON
import {AiOutlineSearch} from "react-icons/ai";

// COMPONENT
import Navbar from "../Component/Navbar";
import Headers from "../Component/Headers";
import EkstrakurikulerArticle from "../Component/EkstrakurikulerArticle";
import PaginationEkstrakurikuler from "../Component/PaginationEkstrakurikuler";
import Footer from "../Component/Footer";


function EkstrakurikulerPage() {
    const [searchParams] = useSearchParams();
    const Page = searchParams.get("page");

    const [ekstrakurikuler, setEkstrakurikuler] = useState({});
    const [ekstrakurikulerFull, setEkstrakurikulerFull] = useState({});

    const [searchTerm, setSearchTerm] = useState("");
    const [loop, setLoop] = useState(true)

    useEffect(() => {
        if (loop) {
            fetchData(Page);
            setLoop(false)
        }
    }, [searchTerm, Page, loop]);

    const fetchData = (Page) => {
        axios.get(`${Config.Api}/api/ekstrakurikulers?populate=%2A&pagination[page]=${Page === null ? 1 : Page}&pagination[pageSize]=6&sort[1]=createdAt:DESC`)
            .then((responses) => {
                if (responses.data) {
                    setEkstrakurikuler(responses.data || {});
                } else {
                    console.error("Data not found in response.");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }

    const fetchDataFull = (value) => {
        axios
            .get(`${Config.Api}/api/ekstrakurikulers?populate=%2A&sort[1]=createdAt:DESC`)
            .then((response) => {
                if (response.data) {
                    if (value === '') return setEkstrakurikulerFull({})
                    setEkstrakurikulerFull(response.data.data.filter(dat => {
                        return dat.attributes?.Ekstrakurikuler.toLowerCase().includes(value.toLowerCase());
                    }) || {});
                } else {
                    console.error("Data not found in response.");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    const handleSearch = (value) => {
        setSearchTerm(value);
        fetchDataFull(value);
    };

    return (
        <>
            <Helmet>
                <title>Ekstrakurikuler | SMK BUDDHI</title>
                <meta name="descripti on"
                      content="Alamat. Jl. Imam Bonjol No.41, RT.002/RW.003, Karawaci, Kec. Tangerang, Banten, 15115."/>
                <meta name="keywords"
                      content="SMK BUDDHI, SMK DI TANGERANG, SMK BUDDHI DHARMA, SMK Tangerang, smk buddhi, SMK Buddhi, ekstrakurikuler smk buddhi, Ekstrakurikuler SMK BUDDHI"/>
                <meta name="author" content="SMK BUDDHI"/>
                <meta property="og:title" content="SMK BUDDHI"/>
                <meta property="og:description"
                      content="Alamat. Jl. Imam Bonjol No.41, RT.002/RW.003, Karawaci, Kec. Tangerang, Banten, 15115."/>
                <meta property="og:url" content={`https://smkbuddhi.sch.id/ekstrakurikuler`}/>
            </Helmet>

            <div className="EkstrakurikulerPage">

                <Navbar/>
                <Headers title="Ekstrakurikuler" desc="Ekstrakurikuler di SMK BUDDHI"/>

                <div className="container mx-auto flex flex-col py-3 justify-center items-center">

                    <div className="flex px-2 mt-6 container justify-end">
                        <div className="relative w-[400px]">
                            <input className="w-full bg-gray-100 border rounded py-2 px-4" placeholder="Search"
                                   onChange={(e) => handleSearch(e.target.value)}/>
                            <AiOutlineSearch className="absolute top-2 right-2 h-6 w-6 text-gray-500"/>
                        </div>
                    </div>

                    <section data-aos="fade-down"
                             className="pe-5 ps-5 grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3 container mx-auto">
                        {ekstrakurikulerFull.length === undefined ? (Object.keys(ekstrakurikuler).length > 0 && ekstrakurikuler.data.map((data, index) =>
                                <EkstrakurikulerArticle key={index} Data={data}/>))
                            : Object.keys(ekstrakurikulerFull).length > 0 && ekstrakurikulerFull.map((data, index) =>
                            <EkstrakurikulerArticle key={index} Data={data}/>)}
                    </section>
                    {Object.keys(ekstrakurikuler).length > 0 ?
                        <PaginationEkstrakurikuler Page={Page === null ? 1 : Page} Data={ekstrakurikuler}/> : ""}

                </div>

                <footer className="bg-red-500">
                    <Footer/>
                </footer>
            </div>
        </>
    );
}

export default EkstrakurikulerPage;
