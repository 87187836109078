import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./Page/Dashboard.jsx";
import LoginPage from "./Login/login.js";

import Berita from "./Page/Berita.jsx";
import BeritaBlog from "./Page/BeritaBlog.jsx";
import BeritaType from "./Page/BeritaType.jsx";

import reportWebVitals from "./reportWebVitals.js";
import EkstrakurikulerPage from "./Page/Ekstrakurikuler.jsx";
import Gallery from "./Page/Gallery.jsx";
import VisiMisi from "./Page/VisiMisi.jsx";
import Fasilitas from "./Page/Fasilitas.jsx";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Peraturan from "./Page/Peraturan.jsx";
import Guru from "./Page/Guru.jsx";
import Prestasi from "./Page/Prestasi.jsx";
import Akuntansi from "./Page/Akuntansi.jsx";
import Multimedia from "./Page/Multimedia.jsx";
import BisnisDigital from "./Page/BisnisDigital";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/berita" element={<Berita />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/visi-misi" element={<VisiMisi />} />
      <Route path="/peraturan" element={<Peraturan />} />
      <Route path="/guru" element={<Guru />} />
      <Route path="/prestasi" element={<Prestasi />} />
      <Route path="/akuntansi" element={<Akuntansi />} />
      <Route path="/bisnis-digital" element={<BisnisDigital/>} />
      <Route path="/dkv" element={<Multimedia />} />
      <Route path="/fasilitas" element={<Fasilitas />} />
      <Route path="/ekstrakurikuler" element={<EkstrakurikulerPage />} />
      <Route path="/berita/:Judul" element={<BeritaBlog />} />
      <Route path="/type/:Type" element={<BeritaType />} />
      <Route path="/" element={<Dashboard />} />
    </Routes>
  </Router>
);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => { });
}

AOS.init({
  once: true,
  duration: 700,
  easing: "ease-in-out",
});

reportWebVitals();
