import {Config} from "../config.js";

// REACT
import React from "react";
import {Helmet} from "react-helmet";

// COMPONENT
import Navbar from "../Component/Navbar";
import Headers from "../Component/Headers";
import Footer from "../Component/Footer";


function ImageDummy({hari, image}) {
    return (
        <div data-aos="fade-down" className="flex flex-col justify-center items-center">
            <img src={image} alt="Peraturan" className="rounded-lg"/>
            <div className="">Seragam {hari}</div>
        </div>
    );
}

function Umum() {
    return (
        <div className="bg-white max-w-4xl shadow-xl rounded-lg">
            <div className="px-6 py-8 space-y-3">
                <h3 className="font-bold uppercase tracking-tight text-xl">Umum</h3>
                <div className="space-y-2">
                    <p>Setiap siswa wajib:</p>
                    <ol className="list-inside list-decimal px-3">
                        <li>Menjunjung tinggi norma-norma agama Buddha.</li>
                        <li>Bersikap sopan santun kepada Bapak/Ibu guru, orang tua, dan sesama insan.</li>
                        <li>Mengikuti upacara bendera pada hari Senin serta hari besar lainnya (sesuai ketentuan).</li>
                        <li>
                            Mengenakan seragam Sekolah sesuai ketentuan, lengkap dengan atributnya. Tidak dibenarkan
                            mengenakan seragam olahraga pada jam pelajaran
                            lain.
                        </li>
                        <li>Menjaga ketertiban, ketenangan, dan kebersihan kelas masing-masing serta lingkungan
                            sekolah.
                        </li>
                        <li>Menjaga nama baik Sekolah di manapun berada.</li>
                        <li>Memiliki perlengkapan belajar seperti buku, alat tulis menulis, dan lain-lain.</li>
                        <li>Membayar uang SPP melalui Bank BCA paling lambat tanggal 10 pada setiap bulannya.</li>
                        <li>Mengikuti kebaktian di Vihara Sekolah dan hari lainnya boleh dilakukan di Vihara tempat
                            tinggal siswa (sesuai ketentuan).
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    );
}

function Khusus() {
    return (
        <div className="bg-white max-w-4xl shadow-xl rounded-lg">
            <div className="px-6 py-8 space-y-3">
                <h3 className="font-bold uppercase tracking-tight text-xl">Khusus</h3>
                <div className="space-y-2">
                    <p>Setiap siswa wajib:</p>

                    <div className="space-y-2">
                        <h3 className="font-bold tracking-tight text-lg">Kehadiran</h3>
                        <ol className="list-inside list-decimal px-3">
                            <li>Hadir di Sekolah selambat-lambatnya 15 menit sebelum jam pelajaran pertama dimulai.</li>
                            <li>Melapor kepada guru piket bila terlambat hadir.</li>
                            <li>
                                Memberi surat keterangan yang sah sesegera mungkin bila tidak bisa hadir di sekolah, dan
                                memberikan surat keterangan dokter bila sakit
                                lebih dari 3 (tiga) hari.
                            </li>
                            <li>
                                Mengajukan permohonan izin kepada wali kelas bila karena sesuatu hal siswa tidak dapat
                                mengikuti pelajaran. Surat permohonan harus
                                ditanda tangani oleh orang tua/wali.
                            </li>
                            <li>Meminta izin kepada guru kelas/wali kelas/guru piket bila akan keluar kelas/sekolah saat
                                jam pelajaran berlangsung.
                            </li>
                            <li>Berada di sekolah sejak jam pelajaran pertama sampai dengan jam pelajaran terakhir.</li>
                        </ol>
                    </div>

                    <div className="space-y-2">
                        <h3 className="font-bold tracking-tight text-lg">Suasana KBM</h3>
                        <ol className="list-inside list-decimal px-3">
                            <li>
                                Segera masuk kelas bila mendengar tanda bel masuk. Tidak bergerombol di muka kelas.
                                (Bila terlambat, mengambil surat izin masuk di
                                TU).
                            </li>
                            <li>Melaksanakan piket kebersihan kelas sesuai dengan Jadwal.</li>
                            <li>Duduk dengan tenang.</li>
                            <li>Bila guru belum hadir, ketua/wakil kelas melapor pada guru piket di kantor.</li>
                            <li>Mengikuti pelajaran dengan tertib dan tidak belajar atau mengerjakan pekerjaan lain.
                            </li>
                            <li>Ulangan dikerjakan dengan jujur.</li>
                            <li>Tidak diperbolehkan mengadakan peringatan ulang tahun di dalam kelas.</li>
                        </ol>
                    </div>

                    <div className="space-y-2">
                        <h3 className="font-bold tracking-tight text-lg">Penggunaan Seragam</h3>
                        <div className="flex flex-wrap justify-center gap-10 items-center p-10 bg-gray-100 rounded-lg">
                            <ImageDummy hari="senin" image={`${Config.Api}/uploads/SENIN_8fa6dc9025.jpg`}/>
                            <ImageDummy hari="selasa" image={`${Config.Api}/uploads/SELASA_02b5a82b84.jpg`}/>
                            <ImageDummy hari="rabu" image={`${Config.Api}/uploads/RABU_6bf32f0ed8.jpg`}/>
                            <ImageDummy hari="kamis" image={`${Config.Api}/uploads/KAMIS_1c04eead2d.jpg`}/>
                            <ImageDummy hari="jumat" image={`${Config.Api}/uploads/JUMAT_f2576b6199.jpg`}/>
                        </div>
                    </div>

                    <div className="space-y-2">
                        <h3 className="font-bold tracking-tight text-lg">Keuntungan Seragam</h3>
                        <ol className="list-inside list-decimal px-3">
                            <li>Kemeja/Blouse OSIS harus selalu dimasukkan ke dalam celana/rok.</li>
                            <li>Kemeja/Blouse harus memakai lambang OSIS di saku sebelah kiri.</li>
                            <li>Seluruh siswa harus berseragam lengkap sesuai dengan ketentuan.</li>
                            <li>Celana panjang (putra) sampai ke mata kaki.</li>
                            <li>Rok (putri) panjangnya harus menutupi lutut dalam sikap berdiri.</li>
                            <li>Semua siswa wajib memakal ikat pinggang warna hitam.</li>
                            <li>Semua siswa tidak diperbolehkan memakai kaos oblong apapun.</li>
                            <li>Semua siswa wajib mengenakan sepatu sekolah berwarna hitam dan kaos kaki putih.</li>
                        </ol>
                    </div>

                    <div className="space-y-2">
                        <h3 className="font-bold tracking-tight text-lg">Rambut</h3>
                        <ol className="list-inside list-decimal px-3">
                            <li>
                                Rambut tidak boleh disemir/diwarnai. Khusus laki-laki rambut dipotong pendek bagian
                                depan 2 cm di atas alis, bagian atas rambut 3 cm
                                dan bagjan belakang tidak menutupi kera baju.
                            </li>
                            <li>Rambut siswa perempuan harus diatur rapih.</li>
                        </ol>
                    </div>

                    <div className="space-y-2">
                        <h3 className="font-bold tracking-tight uppercase text-lg">Dilarang</h3>
                        <ol className="list-inside list-decimal px-3">
                            <li>Merokok selama menjadi siswa-siswi SMK Buddhi.</li>
                            <li>Mencoret-coret bangku, pintu, kursi ataupun perlengkapan lain yang ada di lingkungan
                                sekolah.
                            </li>
                            <li>Menerima tamu tanpa persetujuan Guru/Wali Kelas, Guru Piket, Kepala Sekolah.</li>
                            <li>
                                Membawa, menyimpan, mengedarkan minuman yang beralkohol atau yang memabukkan serta
                                obat-obat terlarang lainnya (ganja, heroin dll).
                            </li>
                            <li>Membawa senjata api, senjata tajam atau benda apapun yang tidak ada hubungannya dengan
                                pelajaran di sekolah.
                            </li>
                            <li>
                                Membawa, menyimpan dan mengedarkan buku, film atau media lainnya yang bertentangan
                                dengan susila dan nilai-nilai budaya serta moral
                                bangsa Indonesia.
                            </li>
                            <li>Berkelahi atau tawuran baik secara perorangan maupun secara masal/kelompok di sekolah
                                atau di luar sekolah.
                            </li>
                            <li>
                                Melakukan tindakan-tindakan yang merugikan secara masal/kelompok di Sekolah atau di luar
                                sekolah atau milik melakukan pengrusakan
                                terhadap benda-benda perorangan demikian juga terhadap milik Sekolah.
                            </li>
                            <li>Memakai perhiasan dan aksesoris apapun (kalung, gelang, anting-anting dll) secara
                                berlebihan.
                            </li>
                            <li>Berkuku panjang atau memakai kutek.</li>
                            <li>Membawa bahan peledak atau petasan.</li>
                            <li>Alpa lebih dari 3 hari.</li>
                            <li>Keluar dari ruang kelas saat pergantian jam.</li>
                            <li>Melakukan kegiatan olah raga di luar jam pelajaran olah raga yang telah ditentukan dan
                                tanpa sepengetahuan guru olahraga.
                            </li>
                        </ol>
                    </div>

                    <div className="space-y-2">
                        <h3 className="font-bold tracking-tight uppercase text-lg">sanksi siswa yang melanggar</h3>
                        <ol className="list-inside list-decimal px-3">
                            <li>Akan diberikan peringatan secara lisan berupa arahan dan nasihat.</li>
                            <li>Diberikan surat pemberitahuan kepada orang tua/wali murid.</li>
                            <li>Diberhentikan sementara (skorsing) antara 3 sampai 6 hari.</li>
                            <li>Akan diberhentikan dan dikembalikan kepada orang tua/wali.</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Peraturan() {

    return (
        <>
            <Helmet>
                <title>Peraturan | SMK BUDDHI</title>
                <meta name="descripti on"
                      content="Alamat. Jl. Imam Bonjol No.41, RT.002/RW.003, Karawaci, Kec. Tangerang, Banten, 15115."/>
                <meta name="keywords"
                      content="SMK BUDDHI, SMK DI TANGERANG, SMK BUDDHI DHARMA, SMK Tangerang, smk buddhi, SMK Buddhi, peraturan smk buddhi, Peraturan SMK BUDDHI"/>
                <meta name="author" content="SMK BUDDHI"/>
                <meta property="og:title" content="SMK BUDDHI"/>
                <meta property="og:description"
                      content="Alamat. Jl. Imam Bonjol No.41, RT.002/RW.003, Karawaci, Kec. Tangerang, Banten, 15115."/>
                <meta property="og:url" content={`https://smkbuddhi.sch.id/peraturan`}/>
            </Helmet>

            <div className="Peraturan">
                <Navbar/>
                <Headers title="Peraturan" desc="Peraturan Sekolah Menengah Kejuruan Perguruan Buddhi."/>

                <div className="ps-5 pe-5">
                    <div className="container mx-auto space-y-8 py-10 flex flex-col justify-center items-center">
                        <Umum/>
                        <Khusus/>
                    </div>
                </div>
                <footer className="bg-red-500">
                    <Footer/>
                </footer>
            </div>
        </>
    );
}

export default Peraturan;
