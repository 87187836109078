import {Config} from "../config";

// REACT
import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet";

// HELPER
import axios from "axios";

// COMPONENT
import Navbar from "../Component/Navbar";
import Headers from "../Component/Headers";
import Keahlian from "../Component/Keahlian";
import Footer from "../Component/Footer";


function BisnisDigital() {

    const [DataKeahlian, setKeahlian] = useState([]);
    const [loop, setLoop] = useState(true)

    useEffect(() => {
        if (loop) {
            Promise.all([
                axios.get(`${Config.Api}/api/keahlians?populate=%2A`),
            ])
                .then((responses) => {
                    if (responses[0].data) {
                        const targetKeahlian = responses[0].data.data.find((i) => i.attributes.Program === 'Bisnis Digital (BD)');
                        setKeahlian(targetKeahlian || {});
                        setLoop(false)
                    } else {
                        console.error("Data not found in response.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [loop]);

    return (
        <>
            <Helmet>
                <title>SMK BUDDHI || Bisnis Digital</title>
                <meta name="descripti on"
                      content="Alamat. Jl. Imam Bonjol No.41, RT.002/RW.003, Karawaci, Kec. Tangerang, Banten, 15115."/>
                <meta name="keywords"
                      content="SMK BUDDHI, SMK DI TANGERANG, SMK BUDDHI DHARMA, SMK Tangerang, smk buddhi, SMK Buddhi, Bisnis Digital Smk Buddhi, SMK Buddhi Bisnis Digital, smk buddhi bisnis digital, bisnis digital smk buddhi"/>
                <meta name="author" content="SMK BUDDHI"/>
                <meta property="og:title" content="SMK BUDDHI"/>
                <meta property="og:description"
                      content="Alamat. Jl. Imam Bonjol No.41, RT.002/RW.003, Karawaci, Kec. Tangerang, Banten, 15115."/>
                <meta property="og:url" content="https://smkbuddhi.sch.id/bisnis-digital"/>
            </Helmet>

            <div className="Bisnis_Digital">
                <Navbar/>
                <Headers title="Bisnis Digital (BD)"
                         desc="Program Keahlian Bisnis Digital (BD) SMK Buddhi"/>
                <Keahlian
                    data={DataKeahlian}
                />
                <footer className="bg-red-500">
                    <Footer/>
                </footer>
            </div>
        </>
    );
}

export default BisnisDigital;
