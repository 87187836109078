import { Config } from "../config.js";
const Keahlian = ({ data }) => {
    return (
        <div className="space-y-20 ps-5 pe-5">
            <div className="flex flex-col lg:flex-row px-2 mt-6 mb-20 container mx-auto justify-center gap-10 lg:items-start items-center">
                <div className="flex flex-col gap-4 text-justify lg:w-1/2 w-full">
                    <h3 className="font-bold uppercase tracking-tight text-xl text-center">Tujuan Program Keahlian {data.attributes?.Program}</h3>
                    <p>
                        {data.attributes?.Text1}
                    </p>
                    <ol className="px-4">
                        {/*<p dangerouslySetInnerHTML={{__html: Judul.attributes?.BlogContent.replace(/!\[(.*?)\]\((.*?)\)/g, '<img src="$2" alt="$1" />')}}/>*/}
                        {data.attributes?.Text.map((item, index) => (

                            <li dangerouslySetInnerHTML={{__html: item.children[0].text}} key={index}/>
                        ))}
                    </ol>
                </div>
                <img src={`${Config.Api}` + data.attributes?.Image.data[0].attributes.url} alt="Keahlian"/>
            </div>
        </div>
    );
};

export default Keahlian