import {Config} from "../config";

// REACT
import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet";

// ROUTER
import {useParams} from "react-router-dom";

// HELPER
import axios from "axios";
import {func} from "../Function/function";

// COMPONENT
import Navbar from "../Component/Navbar";
import Headers from "../Component/Headers";
import TopicNews from "../Component/TopicNews";
import Blog from "../Component/Blog";
import Button from "../Component/Button";
import Sidebar from "../Component/Sidebar";
import Footer from "../Component/Footer";

function BeritaBlog() {

    const {Judul} = useParams();
    const [articles, setArticles] = useState({});
    const [total, setTotal] = useState([]);
    const [loop, setLoop] = useState(true)

    useEffect(() => {
        if (loop) {
            Promise.all([axios.get(`${Config.Api}/api/blogs?populate=%2A&pagination[pageSize]=1000`)])
                .then((responses) => {
                    if (responses[0].data) {
                        setTotal(responses[0].data.meta.pagination);
                        const targetBlog = responses[0].data.data.find((i) => func.toUrl(i.attributes.BlogTitle) === Judul);
                        if (targetBlog === undefined) return (window.location.href = "/berita");
                        setArticles(targetBlog || {});
                        setLoop(false)
                    } else {
                        console.error("Data not found in response.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [Judul, loop]);

    return (
        <>
            <Helmet>
                <title>{Judul.replaceAll("-", " ")} | SMK BUDDHI</title>
                <meta name="descripti on"
                      content="Alamat. Jl. Imam Bonjol No.41, RT.002/RW.003, Karawaci, Kec. Tangerang, Banten, 15115."/>
                <meta name="keywords"
                      content={`SMK BUDDHI, SMK DI TANGERANG, SMK BUDDHI DHARMA, SMK Tangerang, smk buddhi, SMK Buddhi, berita ${Judul.replaceAll("-", " ")} smk buddhi, ${Judul.replaceAll("-", " ")} smk buddhi`}/>
                <meta name="author" content="SMK BUDDHI"/>
                <meta property="og:title" content="SMK BUDDHI"/>
                <meta property="og:description"
                      content="Alamat. Jl. Imam Bonjol No.41, RT.002/RW.003, Karawaci, Kec. Tangerang, Banten, 15115."/>
                <meta property="og:url" content={`https://smkbuddhi.sch.id/berita/${Judul}`}/>
            </Helmet>

            <div className="BeritaBlog">
                <Navbar/>
                <Headers title="SMK BUDDHI NEWS" desc="Berita Berita Terbaru"/>
                <TopicNews/>
                <div className="container mx-auto flex flex-wrap py-6">
                    <section className="w-full md:w-2/3 flex flex-col items-center px-3">
                        <Blog Judul={articles}/>
                        {articles?.id ? <Button Data={articles} Total={total}/> : ""}
                    </section>
                    <Sidebar/>
                </div>
                <footer className="bg-red-500">
                    <Footer/>
                </footer>
            </div>
        </>
    );
}

export default BeritaBlog;
