import {Config} from "../config";

// REACT
import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet";

// HELPER
import axios from "axios";

// ROUTER
import {useSearchParams, useParams} from "react-router-dom";

// COMPONENT
import Navbar from "../Component/Navbar";
import Headers from "../Component/Headers";
import TopicNews from "../Component/TopicNews";
import Article from "../Component/Article";
import Pagination from "../Component/Pagination";
import Sidebar from "../Component/Sidebar";
import Footer from "../Component/Footer";

function BeritaType() {

    const [searchParams] = useSearchParams();
    const {Type} = useParams();
    const Page = searchParams.get("page");

    // BERITA
    const [articles, setArticles] = useState({});
    const [loop, setLoop] = useState(true)

    useEffect(() => {
        if (loop) {
            Promise.all([
                axios.get(
                    `${Config.Api}/api/blogs?filters[BlogType][$eqi]=${Type}&populate=%2A&pagination[page]=${Page === null ? 1 : Page
                    }&pagination[pageSize]=3&sort[1]=createdAt:DESC`
                ),
            ])
                .then((responses) => {
                    if (responses[0].data) {
                        setArticles(responses[0].data || {});
                        setLoop(false)
                    } else {
                        console.error("Data not found in response.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [Page, Type, loop]);


    return (
        <>
            <Helmet>
                <title>Berita Type {Type} | SMK BUDDHI</title>
                <meta name="descripti on"
                      content="Alamat. Jl. Imam Bonjol No.41, RT.002/RW.003, Karawaci, Kec. Tangerang, Banten, 15115."/>
                <meta name="keywords"
                      content={`SMK BUDDHI, SMK DI TANGERANG, SMK BUDDHI DHARMA, SMK Tangerang, smk buddhi, SMK Buddhi, SMK BUDDHI berita ${Type}`}/>
                <meta name="author" content="SMK BUDDHI"/>
                <meta property="og:title" content="SMK BUDDHI"/>
                <meta property="og:description"
                      content="Alamat. Jl. Imam Bonjol No.41, RT.002/RW.003, Karawaci, Kec. Tangerang, Banten, 15115."/>
                <meta property="og:url" content={`https://smkbuddhi.sch.id/type/${Type}`}/>
            </Helmet>

            <div className="BeritaType">
                <Navbar/>
                <Headers title="SMK BUDDHI NEWS" desc="Berita Berita Terbaru"/>
                <TopicNews/>
                <div className="container mx-auto flex flex-wrap py-6">
                    <section className="w-full md:w-2/3 flex flex-col items-center px-3">
                        {Object.keys(articles).length > 0 && articles.data.map((data, index) => <Article key={index}
                                                                                                         Data={data}/>)}
                        {Object.keys(articles).length > 0 ?
                            <Pagination Page={Page === null ? 1 : Page} Data={articles}/> : ""}
                    </section>
                    <Sidebar/>
                </div>
                <footer className="bg-red-500">
                    <Footer/>
                </footer>
            </div>
        </>
    );
}

export default BeritaType;
