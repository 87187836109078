import {Config} from "../config";
import React, {useState, useEffect} from "react";
import axios from "axios";

import Navbar from "../Component/Navbar";
import Headers from "../Component/Headers";
import Keahlian from "../Component/Keahlian";
import Footer from "../Component/Footer";
import {Helmet} from "react-helmet";

function Multimedia() {

    const [DataKeahlian, setKeahlian] = useState([]);
    const [loop, setLoop] = useState(true)

    useEffect(() => {
        if (loop) {
            Promise.all([
                axios.get(`${Config.Api}/api/keahlians?populate=%2A`),
            ])
                .then((responses) => {
                    if (responses[0].data) {
                        const targetKeahlian = responses[0].data.data.find((i) => i.attributes.Program === 'Desain Komunikasi Visual (DKV)');
                        setKeahlian(targetKeahlian || {});
                        setLoop(false)
                    } else {
                        console.error("Data not found in response.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [loop]);

    return (
        <>
            <Helmet>
                <title>SMK BUDDHI || Multimedia</title>
                <meta name="descripti on"
                      content="Alamat. Jl. Imam Bonjol No.41, RT.002/RW.003, Karawaci, Kec. Tangerang, Banten, 15115."/>
                <meta name="keywords"
                      content="SMK BUDDHI, SMK DI TANGERANG, SMK BUDDHI DHARMA, SMK Tangerang, smk buddhi, SMK Buddhi, DKV SMK Buddhi, SMK Buddhi DKV, smk buddhi dkv, dkv smk buddhi, desain komunikasi visual smk buddhi, smk buddhi desain komunikasi visual"/>
                <meta name="author" content="SMK BUDDHI"/>
                <meta property="og:title" content="SMK BUDDHI"/>
                <meta property="og:description"
                      content="Alamat. Jl. Imam Bonjol No.41, RT.002/RW.003, Karawaci, Kec. Tangerang, Banten, 15115."/>
                <meta property="og:url" content="https://smkbuddhi.sch.id/dkv"/>
            </Helmet>

            <div className="Multimedia">
                <Navbar/>
                <Headers title="Desain Komunikasi Visual(DKV)"
                         desc="Program Keahlian Desain Komunikasi Visual(DKV) SMK Buddhi"/>
                <Keahlian
                    data={DataKeahlian}
                />
                <footer className="bg-red-500">
                    <Footer/>
                </footer>
            </div>
        </>
    );
}

export default Multimedia;
